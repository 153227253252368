import React from 'react'
import { useTranslation } from 'react-i18next'
import { MainModal } from 'components/styleComponents/ModalOfferStyles'

// Modal to display a discount pop up when a user first enters the site. Can be toggled on or off by updating the context variable to "false"
const ModalOffer = ({ setShowDiscount }) => {
  const { t } = useTranslation('modalOffer')

  const COUPON_CODE = 'ACADEMYHOLIDAY50'

  return (
    <MainModal>
      <div className="innerModal">
        <div className="modalX">
          <div
            role="presentation"
            className="X"
            onClick={() => {
              setShowDiscount(false)
            }}
            onKeyDown={() => {
              setShowDiscount(false)
            }}
          >
            X
          </div>
        </div>
        <div className="modalContents">
          <h1>{t('title')}</h1>
          <p>{t('text')}</p>
          <h2>{COUPON_CODE}</h2>
        </div>
      </div>
    </MainModal>
  )
}

export default ModalOffer
