// Module Imports
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

// Local imports
import PlayButton from 'components/General/Buttons/PlayButton'

// style imports
import {
  StudentCardContainer,
  StudentCardContents,
  StudentCardImgContainer,
} from 'components/styleComponents/HomePage/HomeStyles'

// asset imports

const StudentCard = ({ studentName, img, videoURL, buyNowToggle }) => {
  const { t } = useTranslation('meetOurStudents')

  // state variables for the toggle of the english teacher videos.
  const [IframeToggle, setIframeToggle] = useState(false)

  return (
    <StudentCardContainer>
      <StudentCardContents>
        <StudentCardImgContainer>
          <h3>{studentName}</h3>
          {/* toggle ternary for the iframe video or the student image.  */}
          {/* Note: we are currently showing a "video coming soon" message just for Nuno until we get his video.*/}
          {!IframeToggle ? (
            <img src={img} alt={`Student named ${studentName}`} />
          ) : (
            <>
              <img
                src={img}
                alt={`Student named ${studentName}`}
                className="blurredImage"
              />
              {studentName === 'Nuno' ? (
                <div className="temporary">{t('comingSoon')}</div>
              ) : (
                <iframe
                  src={videoURL}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title={`Review video for ${studentName}`}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              )}
            </>
          )}
          {/* toggle ternary for the playButton or the closeVideo button.  */}
          {!IframeToggle ? (
            <PlayButton actionFunction={setIframeToggle} parameter={true} />
          ) : (
            <span
              role="button"
              className="closeVideo"
              onClick={() => setIframeToggle(false)}
              onKeyDown={() => setIframeToggle(false)}
              tabIndex={0}
            >
              {t('closeVideo')}
            </span>
          )}
          {buyNowToggle ? (
            <Link to="/courses">{t('meetOurStudentsBuyNow')}</Link>
          ) : null}
        </StudentCardImgContainer>
      </StudentCardContents>
    </StudentCardContainer>
  )
}

export default StudentCard
