// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports
import BuyNowButton from 'components/General/Buttons/BuyNowButton'

// style imports
import {
  LearnInTheMetaverseContainer,
  LearnInTheMetaverseContents,
  GifOverlay,
} from 'components/styleComponents/HomePage/HomeStyles'

// asset imports
import BackgroundVideo from 'videos/fwamainbanner_trimmed.mp4'

const LearnInTheMetaverse = () => {
  const { t } = useTranslation('indexPage')
  return (
    <LearnInTheMetaverseContainer>
      <LearnInTheMetaverseContents>
        <video autoPlay loop muted>
          <source src={BackgroundVideo} type="video/mp4" />
          {t('videoNotSupported')}
        </video>
        <GifOverlay>
          <h1>{t('LearnInMetaverseTitle')}</h1>
          <p>{t('LearnInMetaverseSubtitle')}</p>
          <BuyNowButton />
        </GifOverlay>
      </LearnInTheMetaverseContents>
    </LearnInTheMetaverseContainer>
  )
}

export default LearnInTheMetaverse
