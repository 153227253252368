import styled from '@emotion/styled'

import mq from 'styles/breakpoints'

import holidayModalBackground from 'images/jpg/holidayModal.jpeg/'

export const MainModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 70, 70, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  font-size: 3rem;

  .innerModal {
    background-color: #ffffff;
    background-image: url(${holidayModalBackground});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 1px 1px 1px 1px #888888;
    border-radius: 10px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    width: 60%;
  }

  .modalX {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .X {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #0080fa;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 3rem;
    box-shadow: 1px 1px 1px 1px #888888;
  }

  .modalContents {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: auto;
  }

  ${mq['xl']} {
    font-size: 2.5rem;
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 2.5rem;
    }
  }

  ${mq['md']} {
    font-size: 2rem;
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 2rem;
    }
  }
`
