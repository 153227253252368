// module imports
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// local imports
import Seo from 'components/General/SEO'
import Layout from 'components/Layout'
import LearnInTheMetaverse from 'components/HomePage/LearnInTheMetaverse'
import HowItWorks from 'components/HomePage/HowItWorksComponent'
import LearnWithoutLimits from 'components/General/LearnWithoutLimits/LearnWithoutLimitsComponent'
import TitleAndImage from 'components/General/TitleAndImageComponent'
import MeetOurStudents from 'components/General/MeetOurStudents/MeetOurStudents'
import BenefitsOfFluentWorlds from 'components/HomePage/BenefitsOfFluentWorlds'
import ModalOffer from 'components/HolidayDiscountModal/ModalOffer'
import UserContext from 'context/user/UserContext'

// import style components

// asset imports
import immerseYourselfImage from './../images/png/immerseYourself.png'

const Index = () => {
  const { t } = useTranslation('indexPage')

  const { showDiscount, toggleShowDiscount } = useContext(UserContext)

  return (
    <Layout>
      <Seo title={t('seoTitle')} description={t('seoDescription')} />
      {showDiscount ? (
        <ModalOffer setShowDiscount={toggleShowDiscount} />
      ) : null}
      <LearnInTheMetaverse />
      <HowItWorks />
      <LearnWithoutLimits />
      <TitleAndImage
        title={t('titleAndImageTitle')}
        subtitle={t('titleAndImageSubtitle')}
        image={immerseYourselfImage}
        buttonDisplay={true}
      />
      <MeetOurStudents title={t('meetOurStudentsTitle')} buyNowToggle={true} />
      <BenefitsOfFluentWorlds />
    </Layout>
  )
}

export default Index
