// Module imports
import React from 'react'

// local imports
import StudentCard from './StudentCard'

// style imports
import {
  MeetOurStudentsContainer,
  MeetOurStudentsContents,
  StudentCardsContainer,
} from 'components/styleComponents/HomePage/HomeStyles'

// asset imports
import Student1Image from 'images/jpg/leonardo.jpg'
import Student2Image from 'images/jpg/francisca.jpg'
import Student3Image from 'images/jpg/nuno.jpg'
import Student4Image from 'images/png/camila.png'

const MeetOurStudents = ({ title, buyNowToggle }) => {
  // these are the video URLs for the student reviews. Currently these are only placeholder videos.
  const student1Video = 'https://www.youtube.com/embed/86MYSLYY95A?autoplay=1'
  const student2Video = 'https://www.youtube.com/embed/suwY_0hVmaE?autoplay=1'
  const student3Video = 'https://www.youtube.com/embed/U6Pb_z76CJs?autoplay=1'
  const student4Video = 'https://www.youtube.com/embed/fKjJaNl-WuA?autoplay=1'

  return (
    <MeetOurStudentsContainer>
      <MeetOurStudentsContents>
        <h1>{title}</h1>
        {/* No h3 contents currently. But the h3 stays for consistency and potential future use.*/}
        <h3>&nbsp;</h3>
        <StudentCardsContainer>
          <StudentCard
            studentName="Leonardo"
            img={Student1Image}
            videoURL={student1Video}
            buyNowToggle={buyNowToggle}
          />
          <StudentCard
            studentName="Nuno"
            img={Student3Image}
            videoURL={student3Video}
            buyNowToggle={buyNowToggle}
          />
          <StudentCard
            studentName="Francisca"
            img={Student2Image}
            videoURL={student2Video}
            buyNowToggle={buyNowToggle}
          />
          <StudentCard
            studentName="Camila"
            img={Student4Image}
            videoURL={student4Video}
            buyNowToggle={buyNowToggle}
          />
        </StudentCardsContainer>
      </MeetOurStudentsContents>
    </MeetOurStudentsContainer>
  )
}

export default MeetOurStudents
