// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

// local imports
import useWindowDimensions from 'context/windowDimensions'

// style imports
import {
  HowItWorksContainer,
  HowItWorksContents,
  CardPairContainer,
  HowItWorksCard,
} from '../styleComponents/HomePage/HomeStyles'

// asset imports
import Card1Png from 'images/png/howItWorksCard1.png'
import Card2Png from 'images/png/howItWorksCard2.png'
import Card3Png from 'images/png/howItWorksCard3.png'

const HowItWorks = () => {
  const { t } = useTranslation('indexPage')
  const { width } = useWindowDimensions()
  return (
    <HowItWorksContainer>
      <HowItWorksContents>
        <h1>{t('howItWorksTitle')}</h1>
        <h3>{t('howItWorksSubtitle')}</h3>
        <CardPairContainer>
          <HowItWorksCard>
            <img
              src={Card1Png}
              alt="Woman who is happy she is learning English with FluentWorlds"
            />
          </HowItWorksCard>
          <HowItWorksCard>
            <div className="card-text-contents">
              <h3>{t('howItWorksCard1Title')}</h3>
              <p>{t('howItWorksCard1Description')}</p>
            </div>
            <Link to="/how-it-works">{t('howItWorksLearnMore')}</Link>
          </HowItWorksCard>
        </CardPairContainer>
        {width >= 700 ? (
          <CardPairContainer>
            <HowItWorksCard>
              <div className="card-text-contents">
                <h3>{t('howItWorksCard2Title')}</h3>
                <p>{t('howItWorksCard2Description')}</p>
              </div>
              <Link to="/how-it-works">{t('howItWorksLearnMore')}</Link>
            </HowItWorksCard>
            <HowItWorksCard>
              <img src={Card2Png} alt="Learning English in a 3D-Video Game" />
            </HowItWorksCard>
          </CardPairContainer>
        ) : (
          <CardPairContainer>
            <HowItWorksCard>
              <img src={Card2Png} alt="Learning English in a 3D-Video Game" />
            </HowItWorksCard>
            <HowItWorksCard>
              <div className="card-text-contents">
                <h3>{t('howItWorksCard2Title')}</h3>
                <p>{t('howItWorksCard2Description')}</p>
              </div>
              <Link to="/how-it-works">{t('howItWorksLearnMore')}</Link>
            </HowItWorksCard>
          </CardPairContainer>
        )}
        <CardPairContainer>
          <HowItWorksCard>
            <img
              src={Card3Png}
              alt="FluentWorlds products displayed on mobile devices."
            />
          </HowItWorksCard>
          <HowItWorksCard>
            <div className="card-text-contents">
              <h3>{t('howItWorksCard3Title')}</h3>
              <p>{t('howItWorksCard3Description')}</p>
            </div>
            <Link to="/how-it-works">{t('howItWorksLearnMore')}</Link>
          </HowItWorksCard>
        </CardPairContainer>
      </HowItWorksContents>
    </HowItWorksContainer>
  )
}

export default HowItWorks
