// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports
import BuyNowButton from 'components/General/Buttons/BuyNowButton'

// style imports
import {
  BenefitsOfFluentWorldsContainer,
  BenefitsOfFluentWorldsContents,
  BenefitsImageContainer,
  BenefitsFlexBox,
  BenefitsFlexedDiv,
} from 'components/styleComponents/HomePage/HomeStyles'

// asset imports
import BenefitsImage from 'images/png/benefits.png'

const BenefitsOfFluentWorlds = () => {
  const { t } = useTranslation('indexPage')
  return (
    <BenefitsOfFluentWorldsContainer>
      <BenefitsOfFluentWorldsContents>
        <h1>{t('benefitsTitle')}</h1>
        <h3>&nbsp;</h3>
        <BenefitsImageContainer>
          <img
            src={BenefitsImage}
            alt="A group of students excited about learning languages together."
          />
        </BenefitsImageContainer>
        <BenefitsFlexBox>
          <BenefitsFlexedDiv>
            <h3>{t('benefitsSmallTitle1')}</h3>
            <p>{t('benefitsSmallDescription1')}</p>
          </BenefitsFlexedDiv>
          <BenefitsFlexedDiv>
            <h3>{t('benefitsSmallTitle2')}</h3>
            <p>{t('benefitsSmallDescription2')}</p>
          </BenefitsFlexedDiv>
          <BenefitsFlexedDiv>
            <h3>{t('benefitsSmallTitle3')}</h3>
            <p>{t('benefitsSmallDescription3')}</p>
          </BenefitsFlexedDiv>
          <BenefitsFlexedDiv>
            <h3>{t('benefitsSmallTitle4')}</h3>
            <p>{t('benefitsSmallDescription4')}</p>
          </BenefitsFlexedDiv>
        </BenefitsFlexBox>
        <BuyNowButton />
      </BenefitsOfFluentWorldsContents>
    </BenefitsOfFluentWorldsContainer>
  )
}

export default BenefitsOfFluentWorlds
